<template>
  <div>
    <v-card class="mt-5">
      <p class="small">Note: Fill the form below to create the multimedia.</p>
      <v-app-bar prominent dense color="error" dark>
        <v-toolbar-title>
          <span>Editor ✌</span>
        </v-toolbar-title>
      </v-app-bar>
       <JsonEditor :options = "{  confirmText: 'confirm',
            cancelText: 'cancel'}"  :objData="sampleData" 
        v-model="sampleData"> </JsonEditor>
    </v-card>
    <v-btn @click="createMultimedia()" class="mt-10" color="primary">Create Multimedia</v-btn>
  </div>
</template>


<script>
import RestResource from "../../services/dataServiceMobile";
const service = new RestResource();
export default {
  data() {
    return {
      multimedia: {
          questions:{
             title:[],
             options:[],
          }
      },
      startDate: new Date().toISOString().substr(0, 10),
      expiryDate: new Date().toISOString().substr(0, 10),
      startMenu: false,
      expiryMenu: false,
      countries: [],
      status: [
        {
          text: "Active",
          value: "A"
        },
        {
          text: "Inactive",
          value: "I"
        }
      ],
      itemType: ["youtube", "video", "audio"],
      sampleData: {
    "name" : "name", 
    "description" : "description", 
    "status" : "A/I", 
    "start" : "", 
    "expiry" : "", 
    "itemType" : "youtube", 
    "videoId" : "s59jyEgZPew", 
    "created_by" : "system", 
    "updated_by" : "system", 
    "points" : 5, 
    "country" : "Malaysia", 
    "questions" : [
        {
          
            "title" : {
                "en" : "What brand is this ad for?"
            }, 
            "questionType" : "radio", 
            "options" : [
                {
                    "title" : {
                        "en" : "Corona Beer"
                    }, 
                    "disqualified" : true
                }, 
                {
                    "title" : {
                        "en" : "XXXX Beer"
                    },
                     "disqualified" : true
                }, 
                {
                    "title" : {
                        "en" : "Petronas"
                    }, 
                    "disqualified" : true
                }, 
                {
                    "title" : {
                        "en" : "Microsoft"
                    }, 
                    "disqualified" : true
                }
            ]
        }, 
        {
            "title" : {
                "en" : "How much do you like this ad on a scale of 1 to 5. 1 means do not like it at all and 5 is like it very much."
            }, 
            "questionType" : "rating"
        }
    ]

      }
    };
  },

  mounted() {
    // this.fetchCountries();
  },

  methods: {
    // fetchCountries() {
    //   service.getConfig("countries").then(r => {
    //     this.countries = r.data;
    //   });
    // },

    createMultimedia() {
      this.$setLoader();
      service.createMultimedia(this.sampleData).then(r => {
        r;
        this.$showSuccessMessage(r.data)
      })
    }

  },
  
};
</script>